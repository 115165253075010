/* V2.1 */
:root {
  /* VARIABLES */
  --section-padding: 3rem;
  --section-container-max-width: 1200px;
  --color-primary: #4c51c6;
  --color-primary-shade: #607ff2;
  --color-secondary: #fd830d;
  --color-secondary--tint1: #ca690a;
  --color-background: #e9f4ff;
  --color-text: #000033;
  --color-text-secondary: #1a1a47;
}
::selection {
  background-color: #fd830d;
  color: #fff;
}

/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
  width: 10px;
  height: 14px;
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.95);
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  border: 4px solid transparent;
  /* background-clip: padding-box; */
  border-radius: 1px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-secondary);
}

::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* *:focus {
  outline: none;
  box-shadow: 0 0 0 0.8rem rgba(76, 81, 198, 0.5);
} */
.cta-form *:focus,
.btn:focus {
  outline: none;
  /* box-shadow: 0 0 0 0.8rem rgba(96, 127, 242, 0.5); */
  box-shadow: 0 0 0 0.8rem rgba(112, 116, 209, 1);
}
html {
  /* font-size: 10px; */
  /* we want 10px / most user default 16px = 0.625 = 62.5% */
  /* percentage of user's browser font-size setting */
  font-size: 62.5%;
  overflow-x: hidden;
  /* Does NOT work on Safari */
  scroll-behavior: smooth;
}
body {
  font-family: "Lato", sans-serif;
  line-height: 1;
  font-weight: 400;
  color: #555;
  /* only works if there's nothing absolutely positioned in relation to body */
  overflow-x: hidden;
  background-color: var(--color-background);
  cursor: url("../img/icons/cursor/cursor-normal.png"), auto;
}
.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: var(--color-background);
}
.preloader img {
  animation: fade 2s linear;
  animation-iteration-count: infinite;
}
@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* because we want header to be sticky later */
  height: 9.6rem;
  padding: 0 3.2rem;
  position: relative;
}
/* HEADINGS */
.heading-primary {
  font-size: 3rem;
  line-height: 1.3;
  font-weight: 700;
  color: var(--color-text);
}
.heading-secondary {
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
}
.heading-tertiary {
  font-size: 2.4rem;
  font-weight: 700;
  color: var(--color-text);
}
.body-primary {
  font-size: 1.6rem;
  /* line-height: 1.6; */
  line-height: 1.9;
  color: var(--color-text-secondary);
}
.body-secondary {
  /* for short text */
  font-size: 1.8rem;
  /* line-height: 1.6; */
  line-height: 1.8;
  color: var(--color-text-secondary);
}
.body-tertiary {
  /* for long text */
  font-size: 1.8rem;
  line-height: 1.9;
  color: var(--color-text-secondary);
}
/* CONTAINER */
.section-container {
  max-width: var(--section-container-max-width);
  margin: 0 auto;
}
/* MARGINS */
.margin-bottom--super--small {
  margin-bottom: 1.2rem;
}
.margin-bottom--small {
  margin-bottom: 2.4rem;
}
.margin-bottom--medium {
  margin-bottom: 4.8rem;
}
.padding-top--medium {
  padding-top: 4.8rem;
}
/* RANDOM */
.logo-container {
  border-radius: 5px;
}
.logo {
  height: 2.4rem;
  image-rendering: -webkit-optimize-contrast;
}
.whatsapp-button {
  position: fixed;
  bottom: 15px;
  right: 15px;
  border-radius: 50%;
}
.whatsapp-button img {
  display: block;
  height: 6.2rem;
}

/* CURSOR */
.cta-form label {
  cursor: url("../img/icons/cursor/cursor-normal.png"), auto;
}
*:link,
.cta-form button,
.dots__dot,
.whatsapp-button,
.notification-box button {
  cursor: url("../img/icons/cursor/cursor-pointer.png"), auto;
}
.slider__btn--right {
  cursor: url("../img/icons/cursor/cursor-pointer_right.png"), auto;
}
.slider__btn--left {
  cursor: url("../img/icons/cursor/cursor-pointer_left.png"), auto;
}
.cta-form input,
.cta-form textarea {
  cursor: url("../img/icons/cursor/cursor-normal.png"), auto;
}
/* BUTTONS */
.btn,
.btn:link,
.btn:visited {
  display: inline-block;
  font-weight: 700;
  font-size: 2.4rem;
  padding: 1.6rem 3.2rem;
  border-radius: 15px;
  text-decoration: none;
  border: none;
  transition: all 0.3s;
}
.btn-primary,
.btn-primary:link,
.btn-primary:visited {
  background-color: var(--color-secondary);
  color: #fff;
}
.btn-primary:hover,
.btn-primary:active {
  background-color: var(--color-primary);
}
/* STICKY */
.sticky .main-nav-link:link,
.sticky .main-nav-link:visited {
}
.sticky .main-nav-link:hover,
.sticky .main-nav-link:active {
}
.sticky .icon-mobile-nav {
}
.sticky .header {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 999;
  width: 100%;
  height: 6.4rem;
  background-color: rgba(255, 255, 255, 0.95);
}
.sticky .section-hero {
  margin-top: 9.6rem;
}
/* SECTIONS */
.section {
  transition: transform 1s, opacity 1s;
}
.section-header {
  padding: 2.4rem 3.2rem;
  background-color: var(--color-primary);
}
.hero-img-box,
.features-img-box,
.about-img-box,
.contact-img-box {
  text-align: center;
}
/* .section--hidden {
  opacity: 0;
  transform: translateY(8rem);
} */
.section--hidden--right {
  opacity: 0;
  transform: translateX(8rem);
}
.section--hidden--left {
  opacity: 0;
  transform: translateX(-8rem);
}

/* SECTION HERO */
.section-hero {
  padding: 2.4rem 0 6.4rem 0;
}
.hero {
  display: flex;
  flex-direction: column;
  gap: 6.4rem;
  padding: 0 3.2rem;
}
.hero .heading-primary {
  margin-bottom: 1.2rem;
}
.hero .body-primary {
  margin-bottom: 2.4rem;
}
.hero-img-box img {
  width: 100%;
}
.hero-text-box .decoration {
  display: none;
  position: absolute;
  top: -45%;
  left: -15%;
  width: 40%;
}
.hero-text-box,
.hero-img-box {
  transition: all 1.5s;
}

/* SECTION FEATURES */
.section-features {
  /* padding: 9.6rem 0; */
  padding: 6.4rem 0;
}
.features {
  display: flex;
  flex-direction: column;
  padding: 3.2rem 3.2rem 0 3.2rem;
  gap: 6.4rem;
}
.features-img-box {
}
.features-text-box {
}
.features-img-box img {
  width: 100%;
}
.feature {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  align-items: center;
  column-gap: 1.2rem;
  row-gap: 1.2rem;
}
.feature:not(:last-child) {
  margin-bottom: 6.4rem;
}
.feature p {
  grid-column: span 2;
}
.feature h3 {
  line-height: 1.3;
}

/* SECTION ABOUT */
.section-about {
  /* padding: 9.6rem 0; */
  padding: 6.4em 0;
}
.about {
  display: flex;
  flex-direction: column;
  gap: 6.4rem;
  padding: 3.2rem 3.2rem 0 3.2rem;
  max-width: 700px;
  margin: 0 auto;
}
.about-img-box img {
  width: 100%;
}
.about-text-box h1 {
  margin-bottom: 2.4rem;
}
.section-about .decoration {
  display: none;
}

/* SECTION PROJECTS */
.section-projects {
  padding: 6.4rem 0 0 0rem;
}
.projects {
  background-color: var(--color-primary-shade);
  padding: 6.4rem 3.2rem;
}
.projects-container {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 4.8rem;
  row-gap: 4.8rem;
}
.project {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 100%;
}
.project-img-box {
  /* img */
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 10s;
  /* transition-timing-function: linear; */
  height: 400px;
  width: 100%;
  max-width: 612px;
  border: 1px solid transparent;
  border-radius: 15px;
  display: block;
}
.project-img-box:hover {
  background-position: center 100%;
}
.img--omnifood {
  background-image: url(../img/sections/projects/omnifood_desktop_small.jpg);
}
.img--petcare {
  background-image: url(../img/sections/projects/petcare_desktop.jpg);
}
.img--commerce {
  background-image: url(../img/sections/projects/e-commerce.jpg);
  transition: all 6s;
}
.project-text-box {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  color: var(--color-background);
}
.project-title {
  font-size: 2.4rem;
  margin-bottom: 1.2rem;
}
.project-description {
  font-size: 1.8rem;
  grid-column: 1;
  line-height: 1.5;
}
.project-btn {
  display: none;
  width: 4rem;
  background: none;
  border: none;
  grid-column: 2;
  grid-row: 1 / span 2;
  margin-left: auto;
}

/* SECTION CONTACT */
.section-contact {
  padding: 0 0 9.6rem 0;
  /* background: url("../img/sections/contact/background.svg"); */
  background-repeat: repeat;
  background-repeat: no-repeat;

  /* phone */
  background-position: bottom;
}
.header-container {
  /* padding-top: 6.4rem; */
  background-color: var(--color-primary-shade);
}
.contact {
  display: flex;
  flex-direction: column-reverse;
  gap: 6.4rem;
  padding: 9.6rem 3.2rem 0 3.2rem;
  position: relative;
}
.contact-img-box img:last-child {
  width: 100%;
}
.contact-text-box {
  background-color: var(--color-primary);
  border-radius: 11px;
  /* padding: 4.8rem 2.4rem; */
  padding: 6.4rem 2.4rem;
  max-width: 600px;
  margin: 0 auto;
}
.contact-text-box .heading-secondary,
.contact-text-box .body-primary {
  margin-bottom: 2.4rem;
}
.contact-text-box .heading-secondary {
  line-height: 1.3;
}
.contact-text-box .body-primary {
  color: #fff;
  line-height: 1.9;
}
.cta-form {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
.cta-form button {
  margin-top: 1.2rem;
  width: 100%;
}
.cta-form label {
  display: block;
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0.8rem;
}
.cta-form input,
.cta-form textarea {
  font-family: "Lato", sans-serif;
  border-radius: 5px;
  border: none;
  font-size: 1.6rem;
  padding: 1.6rem;
  color: #444;
  background-color: var(--color-background);
  transition: all 0.3s;
}
.cta-form input {
  width: 100%;
}
.cta-form textarea {
  width: 100%;
  height: 10rem;
  line-height: 1.6;
  resize: none;
  /* height: 5rem; */
}
.cta-form span {
  color: #ddd;
}
.cta-form button:hover,
.cta-form button:active {
  background-color: var(--color-secondary);
}
.notification-box {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.notification-container {
  padding: 12.8rem 6.4rem;
  max-width: 500px;
  margin: 0 auto;
}
.notification {
  display: flex;
  flex-direction: column;
  padding: 6.4rem 4.8rem;
  background-color: var(--color-background);
  border-radius: 15px;
}
.notification-img-box img {
  width: 100%;
  margin-bottom: 2.4rem;
}
.notification-text-box .heading-secondary {
  color: var(--color-text);
  margin-bottom: 2.4rem;
  text-align: center;
}
.notification-text-box .btn {
  width: 100%;
}
.decoration-plane {
  width: 80%;
  position: absolute;
  top: 0.5%;
  right: -30%;
}
.contact-img-box img:last-child {
  max-width: 538px;
}

/* FOOTER */
footer {
  background-color: #1a1a47;
  padding: 4.8rem 3.2rem;
  color: var(--color-background);
}
.footer-links {
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
  margin: 4.8rem 0;
}
.footer-links header {
  font-size: 2.2rem;
  margin-bottom: 2.4rem;
}
.footer-nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.4rem;
}
.social-links {
  display: flex;
  list-style: none;
  gap: 1.2rem;
}
.social-links a:link,
.social-links a:visited {
  transition: all 0.3s;
}
.social-links img {
  height: 3.6rem;
}
.social-links a:active,
.social-links a:hover {
  opacity: 70%;
}
.footer-nav li {
  display: flex;
  gap: 0.8rem;
  align-items: center;
}
.copyright {
  font-size: 1.6rem;
  line-height: 1.5;
  color: var(--color-background);
}
.footer-link:link,
.footer-link:visited {
  text-decoration: none;
  color: var(--color-background);
  font-size: 1.8rem;
  font-weight: 700;
  transition: all 0.3s;
  line-height: 1.3;
}
.footer-link:active,
.footer-link:hover {
  color: var(--color-primary-shade);
}

.footer-nav li img {
  height: 1.8rem;
}
/* MAIN NAV */

.main-nav-link:link,
.main-nav-link:visited {
  font-size: 1.8rem;
  text-decoration: none;
  color: var(--color-text);
  font-weight: 600;
  border-radius: 5px;
  transition: all 0.3s;
}
.main-nav-link:hover,
.main-nav-link:active {
  color: var(--color-primary);
}
.main-nav-list {
  display: flex;
  flex-direction: row;
  gap: 3.2rem;
}
/* MOBILE */
.btn-mobile-nav {
  border: none;
  background: none;
  display: none;
  line-height: 0;
}
.icon-mobile-nav {
  height: 2.4rem;
}
.icon-mobile-nav[name="close-outline"] {
  display: none;
}
.main-nav-list {
  list-style: none;
  line-height: 1;
}
/* QUERIES */
/* from 505px */
@media (min-width: 32em) {
  .hero-img-box img,
  .features-img-box img,
  .about-img-box img,
  .contact-img-box img:last-child {
    width: 80%;
  }
  .decoration-plane {
    width: 70%;
  }
}
/* from 700px */
@media (min-width: 43em) {
  .hero-img-box img,
  .features-img-box img,
  .contact-img-box img:last-child {
    width: 60%;
  }
  .cta-form .btn {
    width: 50%;
  }
  .decoration-plane {
    width: 60%;
    top: 1.8%;
  }
  .project {
    align-items: center;
  }
  .project-text-box {
    text-align: center;
  }
  .footer-links {
    flex-direction: row;
  }
  .footer-contact-links,
  .footer-section-links {
    flex: 1;
  }
}
/* from 944px */
@media (min-width: 59em) {
  .header,
  .hero,
  .features,
  .projects,
  .about,
  .contact,
  footer {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .logo {
    height: 2.4rem;
  }
  .whatsapp-button img {
    height: 7.4rem;
  }
  .section-header {
    text-align: center;
  }
  .hero,
  .features,
  .projects-container,
  .contact,
  .footer-container {
    max-width: 1440px;
    margin: 0 auto;
  }
  .hero-img-box img,
  .features-img-box img,
  .contact-img-box img:last-child {
    width: 100%;
  }
  /* .about-img-box img {
    width: 40%;
  } */
  .hero {
    flex-direction: row-reverse;
    align-items: center;
  }
  .hero-img-box {
    flex: 1.5;
  }
  .hero-text-box {
    position: relative;
    flex: 1;
  }
  .hero-text-box .heading-primary {
    font-size: 3.6rem;
  }
  .hero-text-box .body-primary {
    font-size: 1.8rem;
  }
  .hero-text-box .btn {
    font-size: 2.4rem;
    padding: 1.2rem 3.2rem;
  }
  .features {
    flex-direction: row;
    align-items: center;
    padding-top: 12.8rem;
  }
  .features-text-box,
  .features-img-box {
    flex: 1;
  }

  /* ABOUT DECORATIONS */

  .section-about .decoration {
    display: block;
    position: absolute;
    width: 25%;
  }
  .section-about .decoration--1 {
    top: 78px;
    right: 0;
  }
  .section-about .decoration--2 {
    bottom: -6.4rem;
    left: 0;
  }
  /* .section-about header::before {
    content: "";
    position: absolute;
    width: 50rem;
    height: 25rem;
    top: 78px;
    right: 0;
    background: url("../img/sections/about/decoration.svg");
    background-size: auto;
    background-size: cover;
  } */
  .section-about header {
    position: relative;
  }
  /* .section-about::before {
    content: "";
    position: absolute;
    width: 50rem;
    height: 25rem;
    bottom: -6.4rem;
    left: 0;
    background: url("../img/sections/about/decoration-2.svg");
    background-size: auto;
    background-size: cover;
  } */
  .section-about {
    position: relative;
  }

  /* ////////////////// */

  .projects-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .contact {
    flex-direction: row-reverse;
    align-items: center;
  }
  .contact-img-box,
  .contact-text-box {
    flex: 1;
  }
  .decoration-plane {
    display: none;
    right: -10%;
    width: 50%;
    top: 24.8%;
  }
}
/* 1200px to *** */
@media (min-width: 75em) {
  .footer-nav {
    grid-template-columns: repeat(4, 1fr);
  }
}
/* 1440px to *** */
@media (min-width: 90em) {
  .header {
    padding: 0 4.8rem;
  }
  .hero-text-box .decoration {
    display: block;
  }
  .main-nav-list {
    gap: 6.4rem;
  }
  .section-contact {
    background-size: 100%;
    background-position-y: 500%;
  }
}
/* 0 to 944px */
@media (max-width: 59em) {
  .main-nav {
    background-color: rgba(233, 244, 255, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateX(100%);

    display: flex;
    z-index: 999;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in;
    /* Hide navigation
        allows NO transitions at all
    */
    /* display: none; */
    /* 1) Hide it visually */
    opacity: 0;
    /* 2) Make it unaccessible to mouse and keyboard */
    pointer-events: none;
    /* 3) Hide it from screen readers */
    visibility: hidden;
  }
  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0%);
  }
  .btn-mobile-nav {
    display: block;
    z-index: 9999;
  }
  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0%);
  }
  .main-nav-list {
    display: flex;
    flex-direction: column;
    gap: 4.8rem;
  }
  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 2.4rem;
    text-decoration: none;
    color: #333;
    font-weight: 700;
  }
  .main-nav-link:hover,
  .main-nav-link:active {
    color: var(--color-primary);
  }
  .nav-open .icon-mobile-nav[name="close-outline"] {
    display: block;
  }
  .nav-open .icon-mobile-nav[name="menu-outline"] {
    /* we can use display none because we not need any animation */
    display: none;
  }
}
